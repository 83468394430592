<template>
    <div>
        <card title="Invoice details" body-class="overflow-y-visible">
            <div class="flex flex-wrap w-full">
                <div class="w-1/3 pb-4">
                    <t-input v-model="data.invoice_number" :error="errors.invoice_number" required label="Invoice number"></t-input>
                </div>

                <div class="w-1/3 pb-4 px-6">
                    <modal-field
                        :label="'Invoice date'"
                        :required="true"
                    >
                        <date-picker
                            :disable-past="true"
                            :format-on-emit="true"
                            :error="errors.invoice_date"
                            v-model="data.invoice_date">
                        </date-picker>
                    </modal-field>
                </div>

                <div class="w-1/3 pb-4">
                    <multi-select v-model="data.currency" :error="errors.currency" required :options="currencies" label="Currency"></multi-select>
                </div>

                <div class="w-1/3 pb-4">
                    <t-input v-model="data.freight_charges" label="Freight charges"></t-input>
                </div>

                <div class="w-1/3 pb-4 px-6">
                    <t-input v-model="data.insurance" label="Insurance"></t-input>
                </div>

                <div class="w-1/3 pb-4">
                    <t-input v-model="data.reason_for_export" :error="errors.reason_for_export" required label="Reason for export"></t-input>
                </div>

                <div class="w-1/3 pb-4">
                    <t-input v-model="data.declaration_statement" label="Declaration statement"></t-input>
                </div>

                <div class="w-1/3 pb-4 px-6">
                    <t-input v-model="data.delivery_term" :error="errors.delivery_term" required label="Delivery term (Incoterm)"></t-input>
                </div>

                <div class="w-1/3 pb-4">
                    <t-input v-model="data.vat_no_sender" label="Vat no sender"></t-input>
                </div>

                <div class="w-1/3 pb-4">
                    <t-input v-model="data.vat_no_receiver" label="Vat no receiver"></t-input>
                </div>

                <div class="w-1/3 pb-4 px-6">
                    <t-input v-model="data.vat_no_buyer" label="Vat no buyer"></t-input>
                </div>
            </div>
        </card>

        <div v-if="errors.articles" class="p-4 mt-6 bg-red-300">
            <p>You need to add at least one article.</p>
        </div>

        <card title="Articles" class="mt-8 mb-24">

            <div slot="tools" class="flex">
<!--                <button>-->
<!--                    <fa :icon="['fal', 'link']" class="mr-2"/>Attach files-->
<!--                </button>-->

                <button class="mx-6" @click="importArticlesModal = true">
                    <fa :icon="['fal', 'file-import']" class="mr-2"/>Import articles
                </button>

                <button @click="toggleArticlesModal()">
                    <fa :icon="['fal', 'pen']" class="mr-2"/>Manage articles
                </button>
            </div>

            <div v-if="data.articles.length === 0" class="flex flex-col w-full justify-center items-center my-8 text-gray-600">
                <fa :icon="['fal','box-open']" class="text-8xl mb-4"/>
                <p>No articles has been added yet</p>
                <p>Click manage articles to start</p>
            </div>

            <div v-if="data.articles.length > 0" class="w-full">
                <div class="-mx-6">
                    <table class="table w-full border-b">
                        <tr>
                            <th class="border-b-2">Number of articles</th>
                            <th class="border-b-2">Article number</th>
                            <th class="border-b-2">Description</th>
                            <th class="border-b-2">Net weight</th>
                            <th class="border-b-2">Gross weight</th>
                            <th class="border-b-2">Country of origin</th>
                            <th class="border-b-2">Commodity code</th>
                            <th class="border-b-2">Unit value</th>
                            <th class="border-b-2">Line</th>
                        </tr>
                        <tr v-for="(article, index) in data.articles" :key="`row_${index}`">
                            <td>{{ article.number_of_units }}</td>
                            <td>{{ article.article_number }}</td>
                            <td>{{ article.description }}</td>
                            <td>{{ article.net_weight }}</td>
                            <td>{{ article.gross_weight }}</td>
                            <td>{{ article.country_of_origin }}</td>
                            <td>{{ article.commodity_code }}</td>
                            <td>{{ article.unit_value }}</td>
                            <td>{{ article.line }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </card>

        <modal :show="manageArticlesModal" @hide="toggleArticlesModal()" size="lg">
            <h4 slot="header" class="">Manage articles</h4>

            <div slot="body">
                <div class="overflow-y-auto" style="max-height: calc(100vh - 200px); min-height: 400px;">
                    <div v-for="(article, index) in data.articles" :key="`article_${index}`">
                        <div class="flex w-full" style="">
                            <div class="-mx-3 flex">
                                <div class="flex flex-wrap">
                                    <div class="w-1/5 mt-4 px-3">
                                        <t-input v-model="article.number_of_units" required :error="articlesHasError('number_of_units', index)" label="Number of articles"/>
                                    </div>

                                    <div class="w-1/5 mt-4 px-3">
                                        <t-input v-model="article.article_number" required :error="articlesHasError('article_number', index)" label="Article number"/>
                                    </div>

                                    <div class="w-1/5 mt-4 px-3">
                                        <t-input v-model="article.description" required :error="articlesHasError('description', index)" label="Description"/>
                                    </div>

                                    <div class="w-1/5 mt-4 px-3">
                                        <t-input v-model="article.net_weight" label="Net weight (kg)"/>
                                    </div>

                                    <div class="w-1/5 mt-4 px-3">
                                        <t-input v-model="article.gross_weight" required :error="articlesHasError('gross_weight', index)" class="w-full mr-4" label="Gross weight (kg)"/>
                                    </div>

                                    <div class="w-1/5 mt-4 px-3">
                                        <modal-field label="Country of origin">
                                            <select
                                                v-model="article.country_of_origin"
                                                class="rounded"
                                                :class="{'is-invalid' : articlesHasError('country_of_origin', index)}"
                                            >
                                                <option
                                                    v-for="country in countries"
                                                    :key="country[0]"
                                                    :value="country[0]"
                                                >{{ country[0] + ' - ' + country[1] }}
                                                </option>
                                            </select>

                                            <transition name="fade">
                                                <span v-if="articlesHasError('country_of_origin', index)" class="w-full text-red-500 help-block">
                                                    <span>{{ articlesHasError('country_of_origin', index)[0] }}</span>
                                                </span>
                                            </transition>
                                        </modal-field>
                                    </div>

                                    <div class="w-1/5 mt-4 px-3">
                                        <t-input v-model="article.commodity_code" required :error="articlesHasError('commodity_code', index)" label="Commodity code"/>
                                    </div>

                                    <div class="w-1/5 mt-4 px-3">
                                        <t-input v-model="article.unit_value" required :error="articlesHasError('unit_value', index)" label="Unit value"/>
                                    </div>

                                    <div class="w-1/5 mt-4 px-3">
                                        <multi-select v-model="article.currency" :options="currencies" required :error="articlesHasError('currency', index)" label="Currency"></multi-select>
                                    </div>

                                    <div class="w-1/5 mt-4 px-3">
                                        <multi-select v-model="article.line" :options="lines" required :error="articlesHasError('line', index)" label="Line"></multi-select>
                                    </div>
                                </div>
                            </div>

                            <div class="flex items-center justify-center pl-6 -mt-8">
                                <div class="cursor-pointer" @click="removeArticle(index)">
                                    <fa :icon="['fal', 'times']" class="mr-2 text-xl"/>
                                </div>
                            </div>

                        </div>
                        <hr class="mt-8 mb-4">
                    </div>

                    <button @click="addArticle()" class="">
                        <fa :icon="['fal', 'plus']" class="mr-2"/>Add article
                    </button>
                </div>
            </div>

            <div slot="footer" class="flex w-full">
                <div class="ml-auto">
                    <button @click="toggleArticlesModal()" class="btn-transparent mr-4">Cancel</button>
                    <button @click="toggleArticlesModal()" class="btn">Done</button>
                </div>
            </div>
        </modal>

        <modal :show="importArticlesModal" @hide="importArticlesModal = false" size="sm">
            <h2 slot="header">Import articles</h2>
            <div slot="body" class="">
                <p>
                    Import articles by uploading an Excel file. The file needs to be correct formatted. Download the template below to make sure you have the right format.
                </p>

                <a href="/templates/import-booking-articles.xlsx" download class="mt-6 inline-block text-sm btn-transparent">Download template</a>


                <div class="flex mt-6">
                    <form ref="file-import-form" enctype="multipart/form-data">
                        <label
                            class="inline-block cursor-pointer btn-primary"
                            for="file-import">
                            Select file
                        </label>
                        <input
                            class="hidden"
                            id="file-import"
                            ref="file-import"
                            type="file"
                            name="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="handleFile($event.target.files[0])"
                        />
                    </form>

                    <div class="border-b w-full flex items-center mx-6">
                        <span v-if="!importFile.file" class="text-gray-600">No file has been uploaded yet</span>
                        <span v-else>{{ importFile.name }}</span>
                    </div>
                </div>
            </div>

            <div slot="footer" class="flex w-full">
                <button class="ml-auto mr-4 text-sm btn-transparent" @click="importArticlesModal = false">Cancel</button>
            </div>
        </modal>
    </div>
</template>
<script>

    import TInput from "~/components/TInput";
    import DatePicker from "~/components/DatePicker";
    import MultiSelect from "~/components/MultiSelect";
    import ModalField from "~/components/ModalField";

    export default {
        name: "InvoiceDetails",

        components: {ModalField, MultiSelect, DatePicker, TInput},

        data: () => ({
            manageArticlesModal: false,
            importArticlesModal: false,
            importFile: {},
        }),

        computed: {
            data() {
                return this.$store.getters[`price-leadtime/invoiceDetails`];
            },
            currencies() {
                return this.$store.getters[`price-leadtime/currencies`];
            },
            lines() {
                const goods = this.$store.getters[`price-leadtime/goods`];
                return goods.items.map((good, index) => {
                    return index+1
                })
            },
            currentStep() {
                return this.$store.getters["price-leadtime/currentStep"];
            },
            errors() {
                return this.$store.getters['price-leadtime/activeSteps'][this.currentStep].errors
            },
            countries() {
                return this.$store.getters['countries/list'].map(country => {
                    return [country.iso3, country.name];
                });
            },
        },

        watch: {
            errors() {
                Object.entries(this.errors).forEach(
                    ([key, value]) => {
                        if (key.includes('articles.')) {
                            this.manageArticlesModal = true;
                        }
                    }
                );
            }
        },

        methods: {
            toggleArticlesModal() {
                this.manageArticlesModal = !this.manageArticlesModal;

                if (this.manageArticlesModal && this.data.articles.length === 0) {
                    this.addArticle();
                }
            },

            articlesHasError(key, index) {
                return this.errors[`articles.${index}.${key}`];
            },

            addArticle() {
                this.data.articles.push({
                    number_of_units: null,
                    article_number: null,
                    description: null,
                    net_weight: null,
                    gross_weight: null,
                    country_of_origin: null,
                    commodity_code: null,
                    unit_value: null,
                    currency: null,
                    line: null,
                })
            },

            removeArticle(index) {
                this.data.articles.splice(index, 1)
            },

            handleFile(file) {
                this.importFile.name = file.name;
                this.importFile.file = file;
                this.$forceUpdate()
            },
        },

        async created() {
            await this.$store.dispatch('countries/init');
        },
    }
</script>
